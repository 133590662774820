import React from "react";

const Global = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM22 12C22 12.685 21.93 13.354 21.798 14H17.945C18.066 12.717 18.074 11.379 17.945 10H21.798C21.93 10.646 22 11.315 22 12ZM21.159 8H17.659C17.276 6.04 16.607 4.249 15.711 2.722C18.146 3.699 20.108 5.604 21.159 8ZM15.605 8H13V2.342C14.215 3.802 15.117 5.752 15.605 8ZM11 2.342V8H8.395C8.883 5.752 9.785 3.802 11 2.342ZM11 10V14H8.07C7.924 12.579 7.924 11.423 8.07 10H11ZM11 16V21.658C9.785 20.198 8.883 18.248 8.395 16H11ZM13 21.658V16H15.605C15.117 18.248 14.215 20.198 13 21.658ZM13 14V10H15.93C16.076 11.421 16.076 12.577 15.93 14H13ZM8.289 2.722C7.393 4.249 6.724 6.04 6.341 8H2.841C3.892 5.604 5.854 3.699 8.289 2.722ZM2.202 10H6.055C5.934 11.283 5.926 12.621 6.055 14H2.202C2.07 13.354 2 12.685 2 12C2 11.315 2.07 10.646 2.202 10ZM2.841 16H6.341C6.724 17.96 7.393 19.751 8.289 21.278C5.854 20.301 3.892 18.396 2.841 16ZM15.711 21.278C16.607 19.751 17.276 17.96 17.659 16H21.159C20.108 18.396 18.146 20.301 15.711 21.278Z"
      fill={fill || "black"}
    />
  </svg>
);

export default Global;
