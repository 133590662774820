import React from "react";

const Wings = ({ fill }) => (
  <svg
    width="35"
    height="27"
    viewBox="0 0 35 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5026 15.5028C30.5496 14.472 30.2402 13.4457 29.6308 12.613C29.0214 11.7803 28.137 11.1745 27.1402 10.9074L27.1304 10.9048L27.1647 10.1574C27.2144 9.06715 26.887 7.98164 26.2425 7.10083C25.5982 6.22032 24.6629 5.57964 23.6086 5.29714L5.02008 0.316376L4.78478 1.19455C4.38603 2.68268 4.63673 4.19425 5.36179 5.4277L5.18525 6.08655C4.76478 7.65579 5.06644 9.25129 5.88513 10.5191L5.75028 11.0224C4.96099 13.9681 6.71549 17.007 9.66116 17.7962L10.2396 17.9512C10.3707 20.133 11.8742 22.0882 14.0986 22.6843L29.98 26.9397L30.5026 15.5028ZM7.38029 12.4004L16.6543 14.8854L17.1249 13.129L9.5668 11.1038C7.89247 10.6552 6.79137 9.12363 6.81503 7.46447L16.0893 9.9495L16.5599 8.19316L9.16626 6.21204C7.49171 5.76335 6.39083 4.23185 6.41455 2.57246L23.1379 7.05348C24.4822 7.41367 25.4118 8.68428 25.3483 10.0745L24.8948 19.9957L10.1318 16.0399C8.45744 15.5913 7.35656 14.0598 7.38029 12.4004V12.4004ZM12.1499 18.4631L26.6082 22.3372L27.044 12.7952C28.0645 13.2386 28.7381 14.2851 28.6862 15.4198L28.2667 24.5984L14.5692 20.9282C13.3345 20.5971 12.4404 19.6232 12.1499 18.4631Z"
      fill={fill || "black"}
    />
  </svg>
);

export default Wings;
